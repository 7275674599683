import events from "./eventData.js";

const pastEvents = events
  .filter(event => new Date(event.finalDateText ?? event.dateText) < new Date())
  .sort((event1, event2) => new Date(event2.finalDateText ?? event2.dateText) - new Date(event1.finalDateText ?? event1.dateText));
const upcomingEvents = events
  .filter(event => new Date(event.finalDateText ?? event.dateText) >= new Date())
  .sort((event1, event2) => new Date(event1.finalDateText ?? event1.dateText) - new Date(event2.finalDateText ?? event2.dateText));

  
function getEventElement(event) { 
  const collaborators = event.hasOwnProperty("collaborators") ?
      <><div className="eventInfo">with { event.collaborators }</div></>
      : <></>;
  const location = event.hasOwnProperty("location") ? 
      <><div className="eventInfo">{ event.location }</div></>
      : <></>;
  const moreInformation = event.hasOwnProperty("forMoreInformation") ?
      <><div className="eventInfo">for more information: <a href={event.forMoreInformation.url}>{event.forMoreInformation.text}</a></div></>
      : <></>;
  return <>
    <div className="event">
    <div className="date">{ event.dateText }</div>
    <div>{event.description }</div>
    { collaborators }
    { location }
    { moreInformation }
    </div>
  </>
}

function PastProjects() { return pastEvents.map(event => getEventElement(event)) };
function UpcomingProjects() { return upcomingEvents.map(event => getEventElement(event)) };

export { PastProjects, UpcomingProjects };